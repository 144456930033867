import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_skeleton = _resolveComponent("van-skeleton");

  const _component_van_collapse_item = _resolveComponent("van-collapse-item");

  const _component_van_collapse = _resolveComponent("van-collapse");

  const _component_van_empty = _resolveComponent("van-empty");

  return !_ctx.isComplete ? (_openBlock(), _createBlock(_component_van_skeleton, {
    key: 0,
    title: "",
    style: {
      "margin-top": "50px"
    },
    row: 5
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.list.length > 0 ? (_openBlock(), _createBlock(_component_van_collapse, {
    key: 0,
    modelValue: _ctx.activeNames,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.activeNames = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return _openBlock(), _createBlock(_component_van_collapse_item, {
        title: item.title,
        name: index,
        key: index
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.content), 1)]),
        _: 2
      }, 1032, ["title", "name"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"])) : (_openBlock(), _createBlock(_component_van_empty, {
    key: 1,
    description: "暂无内容",
    style: {
      "margin-top": "200px"
    }
  }))]));
}