import { onMounted, defineComponent, reactive, toRefs, ref } from 'vue';
import { getList } from '@/api/question';
export default defineComponent({
  name: 'Question',
  components: {},

  setup() {
    const activeNames = ref([]);
    const state = reactive({
      list: [],
      isComplete: false
    });

    const getData = async () => {
      const {
        data
      } = await getList();
      state.list = data;
      state.isComplete = true;
    };

    onMounted(() => {
      getData();
    });
    return { ...toRefs(state),
      activeNames
    };
  }

});